import { UIComponent } from "./uiComponent"
import { CanvasTexture } from "nifty-engine/libs/niftyEngine/prototype/canvasTexture"
import { BoxWithPlane } from "./boxWithPlane"
import Konva from "konva"


export class KonvaUI extends BoxWithPlane {
    layer: Konva.Layer
    canvasTexture: CanvasTexture

    constructor(canvasWidth: number, canvasHeight: number) {
        super()
        var div = document.createElement("div")
        div.id = "test"
        document.body.append(div)
        Konva.pixelRatio = 1
        var kStage: any = new Konva.Stage({
            container: "test",
            width: canvasWidth,
            height: canvasHeight,
        });

        this.layer = new Konva.Layer();
        kStage.add(this.layer);
        (kStage as any).content = null // required to get simulated mouse events to work for some reason

        this.canvasTexture = new CanvasTexture(UIComponent.getAssets().device, this.layer.canvas._canvas)
        this.plane.material.albedoTexture = this.canvasTexture.texture


        var hoverPos = { x: 0, y: 0 }
        this.pointer.down.add(() => {
            var evt = {
                type: "mousedown",
                bubbles: true,
                touches: [{
                    clientX: hoverPos.x,
                    clientY: hoverPos.y,
                }],
                changedTouches: [{ identifier: 1 }],
            }
            kStage._mousedown(evt)
        })
        this.pointer.hover.add((e) => {
            hoverPos.x = -1
            hoverPos.y = -1
            if (e.hovered && e.pos) {
                hoverPos.x = e.pos.x * canvasWidth
                hoverPos.y = e.pos.y * canvasHeight
            }
            var evt = {
                type: "mousemove",
                bubbles: true,
                touches: [{
                    clientX: hoverPos.x,
                    clientY: hoverPos.y,
                }],
                changedTouches: [{ identifier: 1 }],
            }
            kStage._mousemove(evt)

        })
    }

    update() {
        this.canvasTexture.update()
    }
}