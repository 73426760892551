import { App, runCanvasMethod } from "../../niftyReality/app"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh";
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { NiftyReality } from "../../niftyReality/niftyReality";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";
import { Stage } from "../../niftyRealityAppHelpers/stage";
import { Ray } from "nifty-engine/libs/niftyEngine/math/ray";
import { Hit, HitResult } from "nifty-engine/libs/niftyEngine/prototype/hit";
import { InstancedRenderer } from "nifty-engine/libs/niftyEngine/prototype/instancedRenderer";
import { CanvasTexture } from "nifty-engine/libs/niftyEngine/prototype/canvasTexture";


NiftyReality.registerApp({
    appName: "Clock",
    iconImage: "/public/img/demoBlocks.png",
    create: async (app: App) => {
        var tmpVector = new Vector3()

        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))
        stage.lights[0].rotation.fromDirection(-1, -1, -1)

        // Load assets
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)

        // Create mesh
        var screen = new Mesh(assets.vertexAttributes.plane, assets.materials.black);
        screen.scale.scaleInPlace(0.3)
        screen.position.set(0, screen.scale.y, 0)
        stage.nodes.push(screen)

        // Create canvas element to draw text to
        var canEl = document.createElement('canvas')
        canEl.width = 512 * 2
        canEl.height = 128 * 2
        var ctx = canEl.getContext("2d")!

        var canvasTexture = new CanvasTexture(stage.device, canEl)

        screen.material.albedoTexture = canvasTexture.texture
        screen.scale.x *= screen.scale.z * canEl.width / canEl.height

        var frameArray = new Array<number>()
        var frameIndex = 0
        var frameSum = 0
        var framesToWatch = 120

        // Render loop
        app.update = (curtime, delta) => {
            // Get time
            var time = new Date()
            var clockTime = time.toLocaleTimeString()//time.getHours() + ":" + time.getMinutes() + ":" + time.getSeconds();

            runCanvasMethod(() => {
                // Draw text
                var size = 50 * 2
                ctx.fillStyle = '#2c3e50';
                ctx.fillRect(0, 0, canEl.width, canEl.height)
                //ctx.clearRect(0, 0, canEl.width, canEl.height)
                ctx.fillStyle = '#ecf0f1';
                ctx.font = size + 'px serif';
                var dim = ctx.measureText("M")
                ctx.fillText("" + clockTime, 10, dim.width);
                if (frameArray.length < framesToWatch) {
                    ctx.fillText("FPS: ...", 10, dim.width * 2 + 10);
                } else {
                    ctx.fillText("FPS: " + (Math.floor(100000 / (frameSum / frameArray.length)) / 100).toFixed(2), 10, dim.width * 2 + 10);
                }
                canvasTexture.update()
            })


            // Update stage
            stage.updateFromFrame(app.getCurrentFrame())

            if (frameArray.length < framesToWatch) {
                frameArray.push(delta)
                frameSum += delta
            } else {
                frameSum -= frameArray[frameIndex]
                frameSum += delta
                frameArray[frameIndex] = delta
                frameIndex = (frameIndex + 1) % framesToWatch
            }




            stage.render()
        }

        // Tell niftyreality if this app has been hit by a ray
        var ray = new Ray()
        var result = new HitResult()
        app.castRay = (world) => {
            stage.rayInAppSpaceFromWorldRayMatrixToRef(world, ray)
            result.reset()
            var minDist = Infinity
            for (var node of stage.nodes) {
                Hit.rayIntersectsMesh(ray, node, result)
                if (result.hitDistance && result.hitDistance < minDist) {
                    minDist = result.hitDistance
                }
            }

            return minDist
        }

        app.dispose = () => {
            assets.dispose()
        }
    }
})
