import { App } from "../../niftyReality/app"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh";
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { NiftyReality } from "../../niftyReality/niftyReality";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";
import { Stage } from "../../niftyRealityAppHelpers/stage";
import { Ray } from "nifty-engine/libs/niftyEngine/math/ray";
import { Hit, HitResult } from "nifty-engine/libs/niftyEngine/prototype/hit";
import { Texture } from "nifty-engine/libs/niftyEngine/gfx/texture";


NiftyReality.registerApp({
    appName: "BasketBall",
    iconImage: "/public/img/demoBlocks.png",
    create: async (app: App) => {
        var tmpVector = new Vector3()

        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))
        stage.lights[0].rotation.fromDirection(-1, -1, -1)

        // Load assets
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)

        var tmpVector = new Vector3()

        // Create mesh

        var bballMat = assets.createMaterial(stage.device)
        bballMat.albedoTexture = await Texture.createFromeURL(stage.device, "/public/img/bball.jpg")
        var bballMesh = new Mesh(assets.vertexAttributes.cube, bballMat);
        bballMesh.scale.scaleInPlace(0.1)
        bballMesh.position.set(0, 1, 0)
        stage.nodes.push(bballMesh)
        var bballObj = {
            mesh: bballMesh,
            vel: new Vector3(),
            grav: new Vector3(0, -9.8, 0)
        }

        var offset = new Vector3(0, -0.2, 0)
        var backboardMeshses = new Array<Mesh<any>>()
        var backboardMat = assets.createMaterial(stage.device)
        backboardMat.albedoTexture = await Texture.createFromeURL(stage.device, "/public/img/backboard.png")
        var backboard = new Mesh(assets.vertexAttributes.cube, backboardMat);
        backboard.scale.scaleInPlace(0.5)
        backboard.scale.x *= 1.4
        backboard.scale.z *= 0.3
        backboard.position.set(0, backboard.scale.y, 0)
        backboard.position.addToRef(offset)
        stage.nodes.push(backboard)
        backboardMeshses.push(backboard)

        for (var i = 0; i < 4; i++) {
            var backboard = new Mesh(assets.vertexAttributes.cube, assets.materials.red);
            backboard.scale.scaleInPlace(0.05)
            backboard.scale.x *= 5
            backboard.position.set(0, 0.5, 0)
            stage.nodes.push(backboard)
            backboardMeshses.push(backboard)
            if (i == 0) {
                backboard.position.set(0, 0.3, -(0.2 - 0.1))
            }
            else if (i == 1) {
                backboard.position.set(0, 0.3, -(0.5 - 0.1))
            } else if (i == 2) {
                backboard.scale.z = backboard.scale.x
                backboard.scale.x = backboard.scale.y
                backboard.position.set(0.15, 0.3, -(0.7 / 2 - 0.1))
            } else if (i == 3) {
                backboard.scale.z = backboard.scale.x
                backboard.scale.x = backboard.scale.y
                backboard.position.set(-0.15, 0.3, -(0.7 / 2 - 0.1))
            }
            backboard.position.addToRef(offset)
        }

        var hitObjects = {} as any
        stage.controllers.forEach((c) => {
            hitObjects[c.hand] = { obj: null }
        })

        // Render loop
        app.update = (curtime, delta) => {
            if (delta > 300) {
                return
            }
            // Update stage
            stage.updateFromFrame(app.getCurrentFrame())

            // Update and render regular mesh
            // mesh.rotation.fromEuler(curtime / 500, curtime / 1000, 0)
            stage.render()

            if (!stage.currentFrame.appFocused) {
                return
            }

            // Spawn bullet when trigger is pressed
            for (var c of stage.controllers) {
                if (c.primaryButton.justDown) {
                    var ray = c.getRay()
                    bballObj.mesh.position.copyFrom(ray.origin)
                    bballObj.vel.copyFrom(ray.direction)
                    bballObj.vel.scaleInPlace(5)
                }
            }

            // Move the ball
            tmpVector.copyFrom(bballObj.grav)
            tmpVector.scaleInPlace(delta / 1000)
            bballObj.vel.addToRef(tmpVector)

            tmpVector.copyFrom(bballObj.vel)
            tmpVector.scaleInPlace(delta / 1000)
            bballObj.mesh.position.addToRef(tmpVector)

            backboardMeshses.forEach((m) => {
                Hit.aabb(bballObj.mesh, m, tmpVector)

                bballObj.mesh.position.addToRef(tmpVector)

                if (tmpVector.length() > 0) {
                    tmpVector.normalizeToRef()
                    tmpVector.x = Math.abs(tmpVector.x)
                    tmpVector.y = Math.abs(tmpVector.y)
                    tmpVector.z = Math.abs(tmpVector.z)
                    tmpVector.scaleInPlace(-0.8)
                    if (tmpVector.x != 0) {
                        bballObj.vel.x *= tmpVector.x
                    }
                    if (tmpVector.y != 0) {
                        bballObj.vel.y *= tmpVector.y
                    }
                    if (tmpVector.z != 0) {
                        bballObj.vel.z *= tmpVector.z
                    }
                }
            })
        }

        // Tell niftyreality if this app has been hit by a ray
        var ray = new Ray()
        var result = new HitResult()
        app.castRay = (world) => {
            stage.rayInAppSpaceFromWorldRayMatrixToRef(world, ray)
            result.reset()
            var minDist = Infinity
            for (var node of stage.nodes) {
                Hit.rayIntersectsMesh(ray, node, result)
                if (result.hitDistance && result.hitDistance < minDist) {
                    minDist = result.hitDistance
                }
            }

            return minDist
        }

        app.dispose = () => {
            assets.dispose()
        }
    }
})
