import { NiftyOS } from "../niftyOS/niftyOS"

var main = async () => {
    var os = new NiftyOS()
    await os.boot()
}
main()
// import { Renderer } from "../../node_modules/nifty-engine/libs/niftyEngine/simpleRenderer/renderer"
// import { Color } from "nifty-engine/libs/niftyEngine/math/color"

// var main = async () => {
//     var renderer = new Renderer()
//     await renderer.init()
//     renderer.camera.position.set(0, 5, 5)

//     var colors = [
//         Color.createFromHex("#222831"),
//         Color.createFromHex("#393E46"),
//         Color.createFromHex("#00ADB5"),
//         Color.createFromHex("#EEEEEE")
//     ]

//     renderer.clearColor.copyFrom(colors[0])

//     var floor = renderer.createCube()
//     floor.scale.set(100, 0.1, 100)
//     floor.material.color.copyFrom(colors[1])
//     renderer.nodes.push(floor)

//     var moon = renderer.createCube()
//     moon.position.set(20, 13, -100)
//     moon.scale.set(20, 20, 1)
//     moon.material.color.copyFrom(colors[3])
//     renderer.nodes.push(moon)

//     {
//         var cubeCount = 100;
//         var cubes = []
//         for (var i = 0; i < cubeCount; i++) {
//             cubes.push(renderer.createCube())
//             cubes[i].scale.set(0.2, 0.5, 0.2)
//             cubes[i].position.y = -0.19
//             cubes[i].position.x += ((-cubeCount / 2) + i) * 0.15
//             cubes[i].position.z = (-Math.sin(cubes[i].position.x / 5) * 30) - 20

//             cubes[i].position.x += 0

//             cubes[i].material.color.copyFrom(colors[2])
//             renderer.nodes.push(cubes[i])
//         }
//     }
//     {
//         var cubeCount = 100;
//         var cubes = []
//         for (var i = 0; i < cubeCount; i++) {
//             cubes.push(renderer.createCube())
//             cubes[i].scale.set(0.2, 0.5, 0.2)
//             cubes[i].position.y = -0.19
//             cubes[i].position.x += ((-cubeCount / 2) + i) * 0.15
//             cubes[i].position.z = (-Math.sin(cubes[i].position.x / 5) * 30) - 20

//             cubes[i].position.x += 0.5

//             cubes[i].material.color.copyFrom(colors[2])
//             renderer.nodes.push(cubes[i])
//         }
//     }


//     renderer.renderLoop = () => {
//         renderer.render()
//     }
// }
// main()