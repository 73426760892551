import { App } from "../../niftyReality/app"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh";
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { NiftyReality } from "../../niftyReality/niftyReality";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";
import { Stage } from "../../niftyRealityAppHelpers/stage";
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial";
import { GLBLoader } from "nifty-engine/libs/niftyEngine/fileLoaders/glbLoader";


NiftyReality.registerApp({
    appName: "PBR Lighting",
    iconImage: "/public/img/demoBlocks.png",
    create: async (app: App) => {
        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))


        // Load assets
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)


        // var res = await GLBLoader.load(stage.device, "/public/img/DamagedHelmet.glb")
        // var mesh = res[0]
        // mesh.rotation.fromEuler(0, Math.PI, 0)
        // stage.nodes.push(mesh)
        // mesh.scale.scaleInPlace(0.3)

        for (var x = -3; x < 4; x++) {
            for (var y = 0; y < 7; y++) {
                var mat = new UberMaterial(stage.device, assets.programs.default)
                mat.albedoColor.set(1, 0, 0, 1)
                mat.metallic = y / 6
                mat.roughness = ((x + 3) / 6) + 0.1
                mat.albedoTexture = assets.textures.default;
                mat.metallicTexture = assets.textures.default;
                mat.roughnessTexture = assets.textures.default;

                var mesh = new Mesh<UberMaterial>(assets.vertexAttributes.sphere, mat)
                mesh.scale.scaleInPlace(0.1)
                mesh.position.y = (mesh.scale.y * y) * 1.1 + 0.1
                mesh.position.x = (mesh.scale.x * x) * 1.1
                stage.nodes.push(mesh)
            }
        }


        // Since rotation of the mesh is a quaternion we'll create an euler roation
        var eulerRot = new Vector3(0, 0, 0)

        // var lightMesh = new Mesh<UberMaterial>(assets.vertexAttributes.cube, assets.materials.default)
        // lightMesh.scale.scaleInPlace(0.01)
        // lightMesh.position.y = 0.3
        // lightMesh.position.z = -0.3
        // stage.nodes.push(lightMesh)

        // Render loop
        app.update = (curtime, delta) => {
            // Update stage
            stage.updateFromFrame(app.getCurrentFrame())

            // Rotate 360 degrees every second around the y axis
            // eulerRot.y += (delta / 1000) * (Math.PI * 0.2)
            // mesh.rotation.fromEuler(eulerRot.x, eulerRot.y, eulerRot.z)

            //lightMesh.position.x = Math.sin(curtime / 1000) / 8
            //stage.lights[0].position.copyFrom(lightMesh.position)

            // Render nodes in the scene
            stage.render()
        }

        app.dispose = () => {
            assets.dispose()
        }
    }
})
