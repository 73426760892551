import { App } from "../../niftyReality/app"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh";
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { NiftyReality } from "../../niftyReality/niftyReality";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";
import { Stage } from "../../niftyRealityAppHelpers/stage";
import { Ray } from "nifty-engine/libs/niftyEngine/math/ray";
import { Hit, HitResult } from "nifty-engine/libs/niftyEngine/prototype/hit";
import { InstancedRenderer } from "nifty-engine/libs/niftyEngine/prototype/instancedRenderer";


NiftyReality.registerApp({
    appName: "Speedbag",
    iconImage: "/public/img/demoBlocks.png",
    create: async (app: App) => {
        var tmpVector = new Vector3()

        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))
        stage.lights[0].rotation.fromDirection(-1, -1, -1)

        // Load assets
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)

        // Create mesh
        var bag = {
            mesh: new Mesh(assets.vertexAttributes.sphere, assets.materials.blue),
            origin: new Vector3(),
            vel: new Vector3()
        }
        bag.mesh = new Mesh(assets.vertexAttributes.sphere, assets.materials.blue);
        bag.mesh.scale.scaleInPlace(0.3)
        bag.mesh.position.set(0, bag.mesh.scale.y, 0)
        stage.nodes.push(bag.mesh)

        bag.origin.copyFrom(bag.mesh.position)

        var tmpV = new Vector3()
        // Render loop
        app.update = (curtime, delta) => {
            // Update stage
            stage.updateFromFrame(app.getCurrentFrame(), delta)

            // Update and render regular mesh
            bag.mesh.rotation.fromEuler(curtime / 500, curtime / 1000, 0)
            stage.render()


            var handDiameter = 0.07
            for (var c of stage.controllers) {
                var ray = c.getRay()
                tmpVector.copyFrom(ray.origin)
                bag.mesh.position.subtractToRef(tmpVector, tmpVector)
                var dist = tmpVector.length() - (handDiameter / 2) - (bag.mesh.scale.y / 2)

                if (dist < 0) {
                    // when hit stop bag vel in that direction
                    bag.vel.projectOntoPlaneToRef(tmpVector)

                    // Push back based on controller velocity
                    tmpV.copyFrom(c.velocity)
                    var d = tmpV.projectOntoVectorToRef(tmpVector)
                    if (d > 0) {
                        bag.vel.addToRef(tmpV)
                    }

                    // Push away to stop intersection
                    tmpVector.normalizeToRef()
                    tmpVector.scaleInPlace(dist)
                    bag.mesh.position.subtractToRef(tmpVector)

                }
            }

            // push bag back to its original starting point
            tmpVector.copyFrom(bag.origin)
            tmpVector.subtractToRef(bag.mesh.position)
            if (tmpVector.length() > 0.05) {
                tmpVector.normalizeToRef()
                tmpVector.scaleInPlace(6)
                tmpVector.scaleToRef(delta / 1000, tmpVector)
                bag.vel.addToRef(tmpVector)
            }

            // Slow bag velocity
            tmpVector.copyFrom(bag.vel)
            tmpVector.scaleInPlace(1)
            tmpVector.scaleInPlace(delta / 1000)
            bag.vel.subtractToRef(tmpVector)

            // Move bag
            tmpVector.copyFrom(bag.vel)
            tmpVector.scaleToRef(delta / 1000)
            bag.mesh.position.addToRef(tmpVector)
        }

        // Tell niftyreality if this app has been hit by a ray
        var ray = new Ray()
        var result = new HitResult()
        app.castRay = (world) => {
            stage.rayInAppSpaceFromWorldRayMatrixToRef(world, ray)
            result.reset()
            var minDist = Infinity
            for (var node of stage.nodes) {
                Hit.rayIntersectsMesh(ray, node, result)
                if (result.hitDistance && result.hitDistance < minDist) {
                    minDist = result.hitDistance
                }
            }

            return minDist
        }

        app.dispose = () => {
            assets.dispose()
        }
    }
})
