import { App } from "../../niftyReality/app"
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { NiftyReality } from "../../niftyReality/niftyReality";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";
import { Stage } from "../../niftyRealityAppHelpers/stage";
import { NiftyOS } from "../niftyOS";
import { UIComponent } from "../../ui/uiComponent";
import { StackPanel, StackPanelOrientation } from "../../ui/stackPanel";
import { DisplayText } from "../../ui/displayText";
import { InteractionHandler } from "../../ui/interactionHandler";
import { Slider } from "../../ui/slider";


NiftyReality.registerApp({
    appName: "Settings",
    iconImage: "/public/img/demoBlocks.png",
    create: async (app: App) => {
        var tmpVector = new Vector3()

        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))
        stage.lights[0].rotation.fromDirection(-1, -1, -1)

        // Load assets
        await UIComponent.initializeAssets(stage.device)
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)

        var interaction = new InteractionHandler(stage)

        var rows = 2;
        var stackPanel = new StackPanel()
        stackPanel.dimensions.set(0.5, 0.2, 0.03)
        stackPanel.position.y += stackPanel.dimensions.y / 2
        stage.nodes.push(stackPanel)

        var brightnessPanel = new StackPanel()
        brightnessPanel.orientation = StackPanelOrientation.HORIZONTAL
        brightnessPanel.dimensions.set(stackPanel.dimensions.x, stackPanel.dimensions.y / rows, stackPanel.dimensions.z)

        var label = new DisplayText()
        label.fontSize = 20
        label.dimensions.set(stackPanel.dimensions.x / 2, stackPanel.dimensions.y / rows, stackPanel.dimensions.z)
        label.text = "Brightness"


        var slider = new Slider()
        slider.value = NiftyOS.GetOS().compositor.multiviewBlit.brightness
        slider.update()
        slider.dimensions.set(stackPanel.dimensions.x / 2, stackPanel.dimensions.y / rows, stackPanel.dimensions.z)
        slider.pointer.down.add(() => {
            NiftyOS.GetOS().setBrightness(slider.value)
        })
        interaction.buttons.push(slider)

        brightnessPanel.addElement(label)
        brightnessPanel.addElement(slider)
        brightnessPanel.updatePositions()

        var title = new DisplayText()
        title.fontSize = 40
        title.dimensions.set(stackPanel.dimensions.x, stackPanel.dimensions.y / rows, stackPanel.dimensions.z)
        title.text = "Settings"

        stackPanel.addElement(title)
        stackPanel.addElement(brightnessPanel)
        stackPanel.updatePositions()

        // Render loop
        app.update = (curtime, delta) => {
            // Update stage
            stage.updateFromFrame(app.getCurrentFrame())

            // Update and render regular mesh
            stage.render()

            interaction.handleButtons()
        }

        // Tell niftyreality if this app has been hit by a ray
        app.castRay = (world) => {
            return interaction.checkHit(world)
        }

        app.dispose = () => {
            assets.dispose()
        }
    }
})
