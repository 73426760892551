import { UIComponent } from "./uiComponent"
export enum StackPanelOrientation {
    VERTICAL,
    HORIZONTAL
}
export class StackPanel extends UIComponent {
    components = new Array<UIComponent>() // [vertIndex][horIndex]
    orientation = StackPanelOrientation.VERTICAL
    constructor() {
        super()
    }
    addElement(element: UIComponent) {
        this.components.push(element)
        this.addChild(element)
        this.updatePositions();
    }

    updatePositions() {
        var curDist = 0
        for (var element of this.components) {
            if (this.orientation == StackPanelOrientation.VERTICAL) {
                element.position.y = (this.dimensions.y / 2) - (element.dimensions.y / 2) - curDist
                curDist += element.dimensions.y
            } else {
                element.position.x = (this.dimensions.x / 2) - (element.dimensions.x / 2) - curDist
                curDist += element.dimensions.x
            }
        }
    }

    getChildrenSize() {
        var curDist = 0
        for (var element of this.components) {
            if (this.orientation == StackPanelOrientation.VERTICAL) {
                curDist += element.dimensions.y
            } else {
                curDist += element.dimensions.x
            }
        }
        return curDist
    }
}