import { UIComponent } from "./uiComponent"
import { CanvasTexture } from "nifty-engine/libs/niftyEngine/prototype/canvasTexture"
import { BoxWithPlane } from "./boxWithPlane"
import { ColorScheme } from "nifty-engine/libs/niftyEngine/prototype/colorSchemes/colorScheme"


export class Slider extends BoxWithPlane {
    canvasTexture: CanvasTexture
    value = 0.1
    hoverValue = this.value
    constructor() {
        super()
        var canEl = document.createElement('canvas')
        this.canvasTexture = new CanvasTexture(UIComponent.getAssets().device, canEl)
        this.plane.material.albedoTexture = this.canvasTexture.texture
        this.update()

        this.pointer.down.add(() => {
            this.value = this.hoverValue
            this.update()
        })
        this.pointer.hover.add((e) => {
            if (e.hovered && e.pos) {
                this.hoverValue = e.pos.x
            }
        })
    }

    update() {
        this.canvasTexture.canvas.width = 512 * this.dimensions.x
        this.canvasTexture.canvas.height = 512 * this.dimensions.y
        this.canvasTexture.context.fillStyle = ColorScheme.dark.toHex();
        this.canvasTexture.context.fillRect(0, 0, this.canvasTexture.canvas.width, this.canvasTexture.canvas.height)
        this.canvasTexture.context.fillStyle = ColorScheme.red.toHex();
        this.canvasTexture.context.fillRect(0, 0, this.canvasTexture.canvas.width * this.value, this.canvasTexture.canvas.height)
        this.canvasTexture.update()
    }
}