import { App, runCanvasMethod } from "../../niftyReality/app"
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { NiftyReality } from "../../niftyReality/niftyReality";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";
import { Stage } from "../../niftyRealityAppHelpers/stage";
import { NiftyOS } from "../niftyOS";
import { UIComponent } from "../../ui/uiComponent";
import { StackPanel } from "../../ui/stackPanel";
import { DisplayText } from "../../ui/displayText";
import { InteractionHandler } from "../../ui/interactionHandler";
import { KonvaUI } from "../../ui/konvaUI";
import Konva from "konva";
import { ColorScheme } from "nifty-engine/libs/niftyEngine/prototype/colorSchemes/colorScheme";


NiftyReality.registerApp({
    appName: "Launcher",
    iconImage: "/public/img/demoBlocks.png",
    create: async (app: App) => {
        var tmpVector = new Vector3()

        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))

        // Setup UI
        var uiWidth = 1500 * 2;
        var uiHeight = 800 * 2;
        var uiScale = 3;
        await UIComponent.initializeAssets(stage.device)
        var stackPanel = new StackPanel()
        stackPanel.dimensions.set(1, uiHeight / uiWidth, 0.03)
        stackPanel.scale.scaleInPlace(uiScale)
        stackPanel.position.y = stackPanel.dimensions.y * stackPanel.scale.y / 2
        stage.nodes.push(stackPanel)
        var kui = new KonvaUI(uiWidth, uiHeight)
        kui.removeChild(kui.box)
        kui.dimensions.set(stackPanel.dimensions.x, stackPanel.dimensions.y, 0.03)
        stackPanel.addElement(kui)
        var interaction = new InteractionHandler(stage)
        interaction.buttons.push(kui)

        var buttonHeight = uiHeight;
        var button = new Konva.Label({
            x: -uiWidth / 4,
            y: 0,
        });
        kui.layer.add(button);
        button.add(new Konva.Text({
            text: "🔥\nNIFTY\nKICK",
            fontFamily: 'Arial',
            fontStyle: 'bold',
            fontSize: 350,
            padding: 80,
            align: "center",
            fill: 'white',
            width: uiWidth,
            height: buttonHeight,
        }));

        var appIndex = 0;
        NiftyOS.GetOS().onRegisterApp = async (a) => {
            var buttonHeight = uiHeight;
            var appColSize = 10;
            var bWidth = 700;
            var bHeight = 130;
            var button = new Konva.Label({
                x: appIndex < appColSize ? uiWidth / 2 : uiWidth / 2 + bWidth + 50,
                y: appIndex < appColSize ? 10 + (appIndex * (bHeight + 30)) : 10 + ((appIndex - appColSize) * (bHeight + 30))
            });
            appIndex++
            kui.layer.add(button);
            button.add(new Konva.Text({
                text: a.appName,
                fontFamily: 'Arial',
                fontSize: 90,
                padding: 30,
                fill: 'white',
                width: uiWidth,
                height: buttonHeight,
            }));
            var rect2 = new Konva.Rect({
                x: button.x(),
                y: button.y(),
                width: bWidth,
                height: bHeight,
                stroke: 'white',
                strokeWidth: 10,
                cornerRadius: 100,
            });
            kui.layer.add(rect2);


            rect2.on("mouseover", (ev) => {
                runCanvasMethod(() => {
                    rect2.stroke(ColorScheme.focus.toHex())
                    rect2.draw();
                    kui.update()
                })
            })
            rect2.on("mouseout", () => {
                runCanvasMethod(() => {
                    rect2.stroke("white")
                    rect2.draw();
                    kui.update()
                })
            })
            rect2.on("mousedown", () => {
                NiftyOS.GetOS().launchApp(a)
            })

            kui.layer.draw();
            kui.update()
        }
        NiftyOS.GetOS().registeredApps.forEach((a) => {
            NiftyOS.GetOS().onRegisterApp(a)
        })

        var hitObjects = {} as any
        stage.controllers.forEach((c) => {
            hitObjects[c.hand] = { obj: null }
        })

        // Render loop
        app.update = (curtime, delta) => {
            // Update stage
            stage.updateFromFrame(app.getCurrentFrame())

            // Update and render regular mesh
            // mesh.rotation.fromEuler(curtime / 500, curtime / 1000, 0)
            stage.render()

            interaction.handleButtons()
        }

        // Tell niftyreality if this app has been hit by a ray
        app.castRay = (world) => {
            return interaction.checkHit(world)
        }

        app.dispose = () => {
        }
    }
})
