import { Matrix4 } from "nifty-engine/libs/niftyEngine/math/matrix4"
import { TransformNode } from "nifty-engine/libs/niftyEngine/sceneGraph/transformNode"
import { XRController } from "../../niftyRealityAppHelpers/xrController"


export class DragComponent {
    static identity = new Matrix4()
    constructor(private objectToDrag: TransformNode) {
    }

    activeController: null | XRController = null
    node = new TransformNode()
    start(controller: XRController) {
        if (this.activeController) {
            return
        }
        this.activeController = controller

        // Update matrix
        var m = new Matrix4()
        controller.computeWorldMatrix()
        this.objectToDrag.computeWorldMatrix()

        // Add child but keep world matrix
        controller.addChild(this.node)
        this.node.setLocalMatrixFromWorldMatrix(this.objectToDrag.worldMatrix)
    }
    update(delta: number) {
        if (!this.activeController) {
            return
        }

        if (Math.abs(this.activeController.primaryJoystick.y) > 0.1) {
            this.node.position.z += this.activeController.primaryJoystick.y * 5 * delta / 1000
            if (this.node.position.z > 0) {
                this.node.position.z = 0
            }
        }

        this.node.computeWorldMatrix()

        this.objectToDrag!.setLocalMatrixFromWorldMatrix(this.node.worldMatrix)
    }
    end() {
        if (!this.activeController) {
            return
        }
        this.activeController = null
    }
    isDragging() {
        return this.activeController != null
    }


}