import { App } from "../../niftyReality/app"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh";
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { NiftyReality } from "../../niftyReality/niftyReality";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";
import { Stage } from "../../niftyRealityAppHelpers/stage";
import { ChatClient } from "../../avatarChatServer/chatClient";
import { Texture } from "nifty-engine/libs/niftyEngine/gfx/texture";
import { Matrix4 } from "nifty-engine/libs/niftyEngine/math/matrix4";

NiftyReality.registerApp({
    appName: "Voice Chat",
    iconImage: "/public/img/demoBlocks.png",
    create: async (app: App) => {
        var tmpVector = new Vector3()

        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))
        stage.lights[0].rotation.fromDirection(-1, -1, -1)

        // Load assets
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)

        // Join chatroom
        var m = new ChatClient()
        await m.joinRoom("test")

        // Create avatar head, todo create/remove based on # of users
        //var d = await Texture.createFromeURL(stage.device, "/public/img/debug/face.png")
        var anchor = new Mesh(assets.vertexAttributes.sphere, assets.materials.black);
        //anchor.material.albedoTexture = d
        anchor.scale.scaleInPlace(0.3)
        anchor.position.set(0, anchor.scale.y, 0)
        stage.nodes.push(anchor)

        // Render loop
        app.update = (curtime, delta) => {
            // Update stage
            stage.updateFromFrame(app.getCurrentFrame())

            //console.log(stage.camera.position.toJSON())
            var tmp = new Matrix4()
            tmp.copyFrom(anchor.localMatrix)
            tmp.inverseToRef(tmp)
            tmp.multiplyToRef(stage.camera.localMatrix, tmp)
            tmp.decompose()

            // Update and render regular mesh
            //mesh.rotation.fromEuler(curtime / 500, curtime / 1000, 0)
            stage.render()
        }


        app.dispose = () => {
            assets.dispose()
            m.dispose()
        }
    }
})
