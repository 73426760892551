import { NiftyReality } from "../../niftyReality/niftyReality"
import { App } from "../../niftyReality/app"
import { Matrix4 } from "nifty-engine/libs/niftyEngine/math/matrix4"
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3"
import { Quaternion } from "nifty-engine/libs/niftyEngine/math/quaternion"
import { Filesystem } from "../../niftyRealityAppHelpers/filesystem"
import { OimoPhysicsWorld } from "nifty-engine/libs/niftyEngine/prototype/physics/oimoPhysicsWorld"
import { TransformNode } from "nifty-engine/libs/niftyEngine/sceneGraph/transformNode"
import { Stage } from "../../niftyRealityAppHelpers/stage"
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice"
import { InteractionHandler } from "../../ui/interactionHandler"
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh"
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial"
import { Texture } from "nifty-engine/libs/niftyEngine/gfx/texture"
import { OimoRigidBody } from "nifty-engine/libs/niftyEngine/prototype/physics/oimoRigidBody"
import { Color } from "nifty-engine/libs/niftyEngine/math/color"

NiftyReality.registerApp({
    appName: "Flight",
    iconImage: "/public/img/flight.png",
    create: async (app: App) => {
        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))
        stage.lights[0].rotation.fromDirection(-1, -1, -1)
        var ih = new InteractionHandler(stage)


        // Load assets
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)

        var fs = new Filesystem()

        var file = fs.getVoxelFile()
        console.log("heres the file:")
        console.log(file)

        var tmpMatrix = new Matrix4()
        var tmpMatrixB = new Matrix4()
        var tmpVector = new Vector3()
        var tmpQuaterion = new Quaternion()
        var world = new OimoPhysicsWorld();

        var plane = new TransformNode()
        plane.position.y = 0.2
        stage.nodes.push(plane)

        var planePhysicsTracker = new OimoRigidBody(plane, world, { type: 'box', size: [0.1, 0.1, 0.3], friction: 0.4, restitution: 0.1, move: true, density: 1 })


        if (file && file.voxels.length > 0) {
            file.voxels.forEach((v) => {
                var mat = new UberMaterial(stage.device, assets.programs.default)
                mat.albedoTexture = assets.textures.default;
                mat.metallicTexture = assets.textures.default;
                mat.roughnessTexture = assets.textures.default;
                mat.albedoTexture = Texture.createFromColor(stage.device, v.color)
                var voxel = new Mesh(assets.vertexAttributes.cube, mat)
                voxel.position.copyFrom(v.position)
                voxel.scale.scaleInPlace(file!.size)
                plane.addChild(voxel)
            })
            // plane.addComponent(new OimoRigidBody(world, { type: 'box', size: [0.1, 0.1, 0.3], friction: 0.4, restitution: 0.1, move: true, density: 1 }))
        } else {
            [{ position: new Vector3(), color: Color.createFromHex("#FFFFFF") }].forEach((v) => {
                var mat = new UberMaterial(stage.device, assets.programs.default)
                mat.albedoTexture = assets.textures.default;
                mat.metallicTexture = assets.textures.default;
                mat.roughnessTexture = assets.textures.default;
                mat.albedoTexture = Texture.createFromColor(stage.device, v.color)
                var voxel = new Mesh(assets.vertexAttributes.cube, mat)
                voxel.position.copyFrom(v.position)
                voxel.scale.scaleInPlace(0.1)
                plane.addChild(voxel)
            })
            // var planeMesh = new MeshObject(os.device)
            // planeMesh.transform.scale.set(0.1, 0.1, 0.3)
            // plane.transform.addChild(planeMesh.transform)
            // plane.addComponent(new OimoRigidBodyComponent(world, { type: 'box', size: [planeMesh.transform.scale.x, planeMesh.transform.scale.y, planeMesh.transform.scale.z], friction: 0.4, restitution: 0.1, move: true, density: 1 }))
        }



        var floor = new Mesh(assets.vertexAttributes.cube, assets.materials.blue)
        floor.scale.set(1, 0.1, 1)
        stage.nodes.push(floor)
        var florrPhysicsTTracker = new OimoRigidBody(floor, world, { type: 'box', size: [floor.scale.x, floor.scale.y, floor.scale.z], friction: 0.4, restitution: 0.1, move: false, density: 1 })


        app.update = (delta) => {
            stage.updateFromFrame(app.getCurrentFrame())
            stage.render()
            if (stage.currentFrame.appFocused) {
                world.update(delta)
                stage.controllers.forEach((c) => {
                    if (c.hoveredTaskbar) {
                        return;
                    }
                    if (c.hand == "left") {
                        var physComp = planePhysicsTracker
                        physComp.body.linearVelocity.y += -c.primaryJoystick.y * 30 * delta


                        plane.localMatrix.decompose(undefined, tmpQuaterion)
                        tmpMatrix.compose(undefined, tmpQuaterion)

                        var dir = new Vector3(0, 0, -c.primaryJoystick.y)
                        dir.rotateByQuaternionToRef(tmpQuaterion, dir)

                        physComp.body.linearVelocity.x = dir.x
                        physComp.body.linearVelocity.y = dir.y
                        physComp.body.linearVelocity.z = dir.z

                        physComp.body.angularVelocity.x = 0
                        physComp.body.angularVelocity.y = 0
                        physComp.body.angularVelocity.z = 0
                    } else {
                        var physComp = planePhysicsTracker
                        plane.computeLocalMatrix()

                        // Compute rotation matrix
                        plane.localMatrix.decompose(undefined, tmpQuaterion)
                        tmpMatrix.compose(undefined, tmpQuaterion)

                        // Create rotation matrix from euler
                        tmpVector.set(0.05 * -c.primaryJoystick.y, 0, 0);
                        tmpQuaterion.fromEuler(tmpVector.x, tmpVector.y, tmpVector.z)
                        tmpMatrixB.compose(new Vector3(0, 0, 0), tmpQuaterion);

                        // rotate rotation matrix by euler
                        tmpMatrix.multiplyToRef(tmpMatrixB, tmpMatrix)


                        // Create rotation matrix from euler
                        tmpVector.set(0, 0, -0.05 * c.primaryJoystick.x);
                        tmpQuaterion.fromEuler(tmpVector.x, tmpVector.y, tmpVector.z)
                        tmpMatrixB.compose(new Vector3(0, 0, 0), tmpQuaterion);

                        // rotate rotation matrix by euler
                        tmpMatrix.multiplyToRef(tmpMatrixB, tmpMatrix)

                        // Set back rotation on object
                        tmpMatrix.decompose(undefined, plane.rotation)

                        physComp.updateFromObject()
                    }

                })
            }
            // world.update(delta)
            // os.inputManager.controllers.forEach((c) => {
            //     if (c.hoveredTaskbar) {
            //         return;
            //     }
            //     if (c.hand == "left") {
            //         var physComp = plane.getComponent<OimoRigidBodyComponent>(OimoRigidBodyComponent.type)!
            //         physComp.body.linearVelocity.y += c.primaryJoystick.y * 30 * delta


            //         plane.transform.localMatrix.decompose(undefined, tmpQuaterion)
            //         tmpMatrix.compose(undefined, tmpQuaterion)

            //         var dir = new Vector3(0, 0, c.primaryJoystick.y)
            //         dir.rotateByMatrixToRef(tmpMatrix, dir)

            //         physComp.body.linearVelocity.x = dir.x
            //         physComp.body.linearVelocity.y = dir.y
            //         physComp.body.linearVelocity.z = dir.z

            //         physComp.body.angularVelocity.x = 0
            //         physComp.body.angularVelocity.y = 0
            //         physComp.body.angularVelocity.z = 0
            //     } else {
            //         var physComp = plane.getComponent<OimoRigidBodyComponent>(OimoRigidBodyComponent.type)!
            //         plane.transform.computeLocalMatrix()

            //         // Compute rotation matrix
            //         plane.transform.localMatrix.decompose(undefined, tmpQuaterion)
            //         tmpMatrix.compose(undefined, tmpQuaterion)

            //         // Create rotation matrix from euler
            //         tmpVector.set(0.05 * c.primaryJoystick.y, 0, 0);
            //         tmpQuaterion.fromEuler(tmpVector)
            //         tmpMatrixB.compose(new Vector3(0, 0, 0), tmpQuaterion);

            //         // rotate rotation matrix by euler
            //         tmpMatrix.multiplyToRef(tmpMatrixB, tmpMatrix)


            //         // Create rotation matrix from euler
            //         tmpVector.set(0, 0, -0.05 * c.primaryJoystick.x);
            //         tmpQuaterion.fromEuler(tmpVector)
            //         tmpMatrixB.compose(new Vector3(0, 0, 0), tmpQuaterion);

            //         // rotate rotation matrix by euler
            //         tmpMatrix.multiplyToRef(tmpMatrixB, tmpMatrix)

            //         // Set back rotation on object
            //         tmpMatrix.decompose(undefined, plane.transform.rotation)

            //         physComp.updateFromObject()
            //     }

            // })
        }

        app.dispose = () => {

        }
    }
})