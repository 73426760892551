import { TransformNode } from "nifty-engine/libs/niftyEngine/sceneGraph/transformNode";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { Texture } from "nifty-engine/libs/niftyEngine/gfx/texture";
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial";
import { Color } from "nifty-engine/libs/niftyEngine/math/color";
import { DefaultVertexData } from "nifty-engine/libs/niftyEngine/prototype/defaultVertexData";
import { UberProgram } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberProgram";
import { VertexData } from "nifty-engine/libs/niftyEngine/gfx/vertexData";
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { Observable } from "nifty-engine/libs/niftyEngine/prototype/observable";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";

class PointerHandler {
    isHovered = false
    hoverPosition = new Vector3()
    // every frame
    updateHover = new Observable<{ hovered: boolean, pos: Vector3 | null }>()
    // only on change
    hover = new Observable<{ hovered: boolean, pos: Vector3 | null }>()
    down = new Observable()
    up = new Observable()

    constructor() {
        this.updateHover.add((e) => {
            if (!e.pos) {
                e.pos = this.hoverPosition
            }
            if (this.isHovered != e.hovered || !this.hoverPosition.equals(e.pos)) {
                this.hoverPosition.copyFrom(e.pos)
                this.isHovered = e.hovered
                this.hover.notifyObservers(e)
            }
        })
    }
}

export class UIComponent extends TransformNode {
    static assets_: SimpleAssetPack;
    dimensions = new Vector3(1, 1, 1)

    pointer = new PointerHandler()

    constructor() {
        super()
    }

    static async initializeAssets(device: GPUDevice) {
        this.assets_ = new SimpleAssetPack()
        await this.assets_.load(device);
    }
    static getAssets() {
        if (!this.assets_) {
            throw "await UIComponent.initializeAssets(device) must be called before using ui components"
        }
        return this.assets_
    }

}