import { AppContainer } from "./appContainer";
import { Compositor } from "../compositor/compositor";
import { NiftyOS } from "../niftyOS";
import { XRController } from "../../niftyRealityAppHelpers/xrController";

export class AppManager {
    activeApp: AppContainer | null = null;
    appContainers = new Array<AppContainer>()

    // isActiveForApp(app: App) {
    //     return this.activeApp && this.activeApp.app == app
    // }

    constructor(private compositor: Compositor, private os: NiftyOS) {

    }
    update(delta: number, curTime: number, controllers: Array<XRController>) {
        // this.appContainers.forEach((c) => {
        //     c.update(delta, curTime)
        // })
    }

    disposeApp(container: AppContainer) {
        var ind = this.appContainers.indexOf(container)
        if (ind > -1) {
            this.appContainers.splice(ind, 1)
        }
        container.dispose()
    }

    createApp() {
        var appContainer = new AppContainer(this.compositor.createAppFramebuffer(), this.os);
        this.appContainers.push(appContainer)

        // appContainer.containerSpace.transform.position.set(0, 0.8, -1.5)

        return appContainer
    }

}