import { NiftyReality } from "../../niftyReality/niftyReality"
import { App } from "../../niftyReality/app"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh"
import { Stage } from "../../niftyRealityAppHelpers/stage"
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice"
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack"
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3"
import { Matrix4 } from "nifty-engine/libs/niftyEngine/math/matrix4"
import { Color } from "nifty-engine/libs/niftyEngine/math/color"
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial"
import { InteractionHandler } from "../../ui/interactionHandler"
import { StackPanel } from "../../ui/stackPanel"
import { DisplayText } from "../../ui/displayText"
import { UIComponent } from "../../ui/uiComponent"
import { VoxelFile, Filesystem } from "../../niftyRealityAppHelpers/filesystem"

NiftyReality.registerApp({
    appName: "Voxel editor",
    iconImage: "/public/img/edit3D.png",
    create: async (app: App) => {
        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))
        stage.lights[0].rotation.fromDirection(-1, -1, -1)
        var ih = new InteractionHandler(stage)


        // Load assets
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)

        var voxels: { [key: string]: Mesh<UberMaterial> } = {}

        var right = new Mesh(assets.vertexAttributes.sphere, assets.materials.blue)
        right.scale.scaleInPlace(0.01)
        stage.nodes.push(right)

        // var left = new MeshObject(os.device)
        // left.scale.scaleInPlace(0.03)
        // app.scene.addChild(left)

        var tmpVec = new Vector3()
        var tmpMat = new Matrix4()

        // var controllerRaySystem = new ControllerRaySystem(app)

        // Colors
        var colors: { [key: string]: UberMaterial } = {}
        colors["Green"] = assets.materials.green
        colors["Blue"] = assets.materials.blue
        colors["Purple"] = assets.materials.purple
        colors["Black"] = assets.materials.black
        colors["Yellow"] = assets.materials.yellow
        colors["Orange"] = assets.materials.orange
        colors["Red"] = assets.materials.red
        colors["White"] = assets.materials.white
        colors["Gray"] = assets.materials.gray
        var activeColor = colors["White"]
        var index = 0

        await UIComponent.initializeAssets(stage.device)
        var colorPicker = new StackPanel()
        colorPicker.scale.scaleInPlace(0.4)
        colorPicker.dimensions.set(1 / 4, 1, 0.03)
        colorPicker.position.y = colorPicker.scale.y * colorPicker.dimensions.y / 2
        colorPicker.position.x = 0.4
        stage.nodes.push(colorPicker)


        for (let name in colors) {
            var label = new DisplayText()
            label.dimensions.set(1 / 4, colorPicker.dimensions.y / 9, 0.03)
            label.text = name
            colorPicker.addElement(label)
            ih.buttons.push(label)
            label.pointer.down.add(() => {
                activeColor = colors[name]
            })
            // index++
            // var colorButton = new Button(os.device, name)
            // colorButton.mesh.position.x -= 0.3
            // colorButton.mesh.scale.scaleInPlace(0.2)
            // colorButton.backgroundColor.copyFrom(colors[name])
            // colorButton.backgroundColor.scaleInPlace(0.7)
            // colorButton.hoveredBackgroundColor.copyFrom(colors[name])
            // colorButton.hoveredBackgroundColor.scaleInPlace(0.9)
            // colorButton.pointerEvent.onClick = () => {
            //     activeColor = colors[name]
            // }
            // app.scene.addChild(colorButton.mesh)
            // colorButton.mesh.position.y = 0.2 - (0.03 * index)
            // controllerRaySystem.hitable.push(colorButton.mesh)
            // colorButton.update()
        }


        // Save/clear
        var saveClear = new StackPanel()
        saveClear.scale.scaleInPlace(0.4)
        saveClear.dimensions.set(1 / 4, 1, 0.03)
        saveClear.position.y = saveClear.scale.y * saveClear.dimensions.y / 2
        saveClear.position.x = -0.4
        stage.nodes.push(saveClear)

        var fs = new Filesystem()

        var saveBtn = new DisplayText()
        saveBtn.dimensions.set(1 / 4, saveClear.dimensions.y / 9, 0.03)
        saveBtn.text = "Save"
        saveClear.addElement(saveBtn)
        ih.buttons.push(saveBtn)
        saveBtn.pointer.down.add(() => {
            var voxelFile = new VoxelFile()
            voxelFile.size = 0.03
            for (var key in voxels) {
                voxelFile.voxels.push({ position: voxels[key].position, color: (voxels[key].material).albedoColor! })
            }
            fs.saveVoxelFile(voxelFile)
            console.log(voxelFile)
            console.log("saved")
        })

        var clearBtn = new DisplayText()
        clearBtn.dimensions.set(1 / 4, saveClear.dimensions.y / 9, 0.03)
        clearBtn.text = "Clear"
        saveClear.addElement(clearBtn)
        ih.buttons.push(clearBtn)
        clearBtn.pointer.down.add(() => {
            for (var key in voxels) {
                var index = stage.nodes.indexOf(voxels[key])
                stage.nodes.splice(index, 1)
            }
            voxels = {}
        })


        // var saveButton = new Button(os.device, "Save")
        // saveButton.mesh.position.x += 0.5
        // saveButton.pointerEvent.onClick = () => {
        //     var voxelFile = new VoxelFile()
        //     voxelFile.size = 0.03
        //     for (var key in voxels) {
        //         voxelFile.voxels.push({ position: voxels[key].position, color: (voxels[key].material).albedoColor! })
        //     }
        //     os.filesystem.saveVoxelFile(voxelFile)
        // }
        // app.scene.addChild(saveButton.mesh)
        // saveButton.mesh.position.y += 0.13
        // controllerRaySystem.hitable.push(saveButton.mesh)

        // var clearButton = new Button(os.device, "Clear")
        // clearButton.mesh.position.x += 0.5
        // clearButton.pointerEvent.onClick = () => {
        //     for (var key in voxels) {
        //         app.scene.removeChild(voxels[key])
        //     }
        //     voxels = {}
        // }
        // app.scene.addChild(clearButton.mesh)
        // controllerRaySystem.hitable.push(clearButton.mesh)


        app.castRay = (world: Float32Array) => {
            return ih.checkHit(world)
        }

        var voxelSize = 0.03
        app.update = (delta) => {
            // Update stage
            stage.updateFromFrame(app.getCurrentFrame())

            var pointerVisible = false;

            if (stage.currentFrame.appFocused) {
                ih.handleButtons()

                stage.controllers.forEach((c) => {
                    if (c.hand != "left") {
                        var ray = c.getRay()
                        right.position.copyFrom(ray.origin)
                        tmpVec.copyFrom(ray.direction)
                        tmpVec.scaleInPlace(0.3);
                        right.position.addToRef(tmpVec, right.position)

                        // Make not visible when over a button
                        var index = stage.nodes.indexOf(right);
                        if (ih.hitObjects[c.hand] && ih.hitObjects[c.hand].obj) {
                            pointerVisible = false
                            return
                        } else {
                            pointerVisible = true
                        }

                        // if ((controllerRaySystem.castResults[c.hand] && controllerRaySystem.castResults[c.hand].hitDistance) || c.hoveredTaskbar) {
                        //     right.mesh.visible = false
                        //     return
                        // }
                        // right.mesh.visible = true

                        if (c.primaryButton.justDown) {
                            var voxel = new Mesh(assets.vertexAttributes.cube, activeColor)
                            voxel.scale.scaleInPlace(voxelSize)
                            stage.nodes.push(voxel)
                            voxel.position.copyFrom(right.position)

                            voxel.position.scaleInPlace(1 / (voxelSize))
                            voxel.position.x = Math.round(voxel.position.x)
                            voxel.position.y = Math.round(voxel.position.y)
                            voxel.position.z = Math.round(voxel.position.z)
                            voxel.position.scaleInPlace((voxelSize))

                            voxels[Object.keys(voxels).length] = voxel
                        }
                    }
                })
            }

            // Make not visible when over a button
            var index = stage.nodes.indexOf(right);
            if (!pointerVisible) {
                if (index != -1) {
                    stage.nodes.splice(stage.nodes.indexOf(right), 1)
                }
            } else {
                if (index == -1) {
                    stage.nodes.push(right)
                }
            }


            stage.render()
        }

        app.dispose = () => {

        }
    }
})