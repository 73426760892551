import * as scli from "socket.io-client";

export class Call {
    playbackElement: HTMLAudioElement
    rtc: RTCPeerConnection
    userId = ""
    constructor(public stream: MediaStream, public socket: scli.Socket) {
        this.playbackElement = document.createElement("audio")
        this.playbackElement.style.position = "absolute"
        this.playbackElement.style.top = "0px"
        this.playbackElement.style.left = "0px"
        this.playbackElement.autoplay = true

        this.rtc = new RTCPeerConnection({
            //iceTransportPolicy: 'relay',
            iceServers: [
                { urls: "stun:stun.reality.niftykick.com:3478" },
                {
                    urls: "turn:turn.reality.niftykick.com:3478",
                    username: "guest", // do i need to populate this?
                    credential: "somepasswordx"
                },
            ]
        })
        stream.getTracks().forEach(track => this.rtc.addTrack(track, stream));

        this.rtc.addEventListener("track", (e) => {
            this.playbackElement.srcObject = e.streams[0]
        })

        this.rtc.addEventListener("icecandidate", (e) => {
            if (this.userId) {
                this.socket.emit("sendMessage", {
                    to: this.userId, data: {
                        action: "icecandidate",
                        candidate: e.candidate,
                    }
                })
            }
        })
    }

    async callUser(id: string) {
        this.userId = id
        var offer = await this.rtc.createOffer({ offerToReceiveVideo: false, offerToReceiveAudio: true });
        await this.rtc.setLocalDescription(offer);
        this.socket.emit("sendMessage", {
            to: id, data: {
                action: "callRequest",
                desc: this.rtc.localDescription,
            }
        })
    }

    async receiveCallFromuser(id: string, desc: RTCSessionDescription) {
        this.userId = id
        await this.rtc.setRemoteDescription(desc);
        var answerDesc = await this.rtc.createAnswer()
        this.rtc.setLocalDescription(answerDesc)
        this.socket.emit("sendMessage", {
            to: id, data: {
                action: "answerCall",
                desc: answerDesc,
            }
        })
    }

    dispose() {
        this.rtc.close();
        this.playbackElement.srcObject = null;
        this.playbackElement.src = "";
        (this.playbackElement as any) = null
    }
}
export class ChatClient {
    socket: scli.Socket;
    stream: MediaStream;

    // For every user in the room we need to stoare a call with them
    calls: { [id: string]: Call } = {};;
    constructor() {
        // Socketio
        var serverUrl = window.location.origin
        this.socket = scli.default(serverUrl + "/chatServer")
    }


    async joinRoom(room: string) {
        // Init mic
        this.stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false })

        // join socketIO room
        this.socket.emit("joinRoom", { roomName: room })

        // When another user joins the room call them
        this.socket.on("joinedRoom", async (d: any) => {
            var call = new Call(this.stream, this.socket)
            this.calls[d.user.id] = call
            call.callUser(d.user.id)

        })

        // When peer leaves cleanup call
        this.socket.on("leftRoom", async (d: any) => {
            if (!this.calls[d.user.id]) {
                return
            }
            this.calls[d.user.id].dispose()
            delete this.calls[d.user.id]
        })

        this.socket.on("receiveMessage", async (d: any) => {
            if (d.data.action == "callRequest") {
                // Received a call when you joined the room
                var call = new Call(this.stream, this.socket)
                this.calls[d.from] = call
                call.receiveCallFromuser(d.from, d.data.desc)
            } else if (d.data.action == "answerCall") {
                // The call request sent out has now been answered
                var call = this.calls[d.from]
                if (!call) {
                    return
                }
                await call.rtc.setRemoteDescription(d.data.desc);

            } else if (d.data.action == "icecandidate") {
                // Add possible connection
                var call = this.calls[d.from]
                if (!call) {
                    return
                }
                if (d.data.candidate != null) {
                    console.log(d.data.candidate)
                    console.log(d.data.candidate.candidate.indexOf("relay"))
                    call.rtc.addIceCandidate(d.data.candidate)
                }
            }
        })
    }

    dispose() {
        this.socket.close()

        for (var key in this.calls) {
            this.calls[key].dispose()
            delete this.calls[key]
        }

        this.stream.getTracks().forEach(function (track) {
            track.stop();
        });
    }
}