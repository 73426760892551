import { UIComponent } from "./uiComponent"
import { CanvasTexture } from "nifty-engine/libs/niftyEngine/prototype/canvasTexture";
import { BoxWithPlane } from "./boxWithPlane";
import { runCanvasMethod } from "../niftyReality/app";

export class DisplayText extends BoxWithPlane {
    canvasTexture: CanvasTexture
    fontSize_ = 30
    text_ = ""
    backgroundHoverColor_: string;
    backgroundNormalColor_: string;
    textColor_: string;
    backgroundColor_: string;
    set text(v: string) {
        this.text_ = v

        this.update()
    }
    get text() {
        return this.text_;
    }
    set fontSize(s: number) {
        this.fontSize_ = s
        this.update()
    }
    constructor() {
        super()
        var canEl = document.createElement('canvas')
        this.canvasTexture = new CanvasTexture(UIComponent.getAssets().device, canEl)
        this.plane.material.albedoTexture = this.canvasTexture.texture

        this.backgroundHoverColor_ = UIComponent.getAssets().materials.focus.albedoColor.toHex()
        this.backgroundNormalColor_ = UIComponent.getAssets().materials.dark.albedoColor.toHex()
        this.backgroundColor_ = this.backgroundNormalColor_
        this.textColor_ = UIComponent.getAssets().materials.light.albedoColor.toHex();
        this.pointer.hover.add(() => {
            this.backgroundColor_ = this.pointer.isHovered ? UIComponent.getAssets().materials.focus.albedoColor.toHex() : UIComponent.getAssets().materials.dark.albedoColor.toHex()
            console.log("update")
            runCanvasMethod(() => {
                this.update()
            })
        })
    }
    update() {
        var aspectRatio = this.dimensions.y / this.dimensions.x

        var size = this.fontSize_
        this.canvasTexture.canvas.width = 512 * this.dimensions.x
        this.canvasTexture.canvas.height = 512 * this.dimensions.y
        this.canvasTexture.context.fillStyle = this.backgroundColor_
        this.canvasTexture.context.fillRect(0, 0, this.canvasTexture.canvas.width, this.canvasTexture.canvas.height)
        this.canvasTexture.context.fillStyle = this.textColor_
        this.canvasTexture.context.font = size + 'px serif';
        var dim = this.canvasTexture.context.measureText("M")

        var cars = this.text.split("\n");
        var y = dim.width * 1.1
        var x = 10
        for (var ii = 0; ii < cars.length; ii++) {

            var line = "";
            var words = cars[ii].split(" ");

            for (var n = 0; n < words.length; n++) {
                var testLine = line + words[n] + " ";
                var metrics = this.canvasTexture.context.measureText(testLine);
                var testWidth = metrics.width;

                if (testWidth > this.canvasTexture.canvas.width) {
                    this.canvasTexture.context.fillText(line, x, y);
                    line = words[n] + " ";
                    y += dim.width + 20;
                }
                else {
                    line = testLine;
                }
            }

            this.canvasTexture.context.fillText(line, x, y);
            y += dim.width + 20;
        }

        //this.canvasTexture.context.fillText(this.text, 10, dim.width);
        this.canvasTexture.update()
    }
}