import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice"
import { RenderWindow } from "nifty-engine/libs/niftyEngine/gfx/renderWindow"
import { Loop } from "nifty-engine/libs/niftyEngine/prototype/loop"
import { MultiviewBlit } from "nifty-engine/libs/niftyEngine/prototype/multiviewBlit"
import { CompositorPostProcess } from "nifty-engine/libs/niftyEngine/prototype/compositorPostProcess"
import { MultiviewFramebuffer } from "nifty-engine/libs/niftyEngine/prototype/multiviewFramebuffer"
import { Framebuffer } from "nifty-engine/libs/niftyEngine/gfx/framebuffer"
import { AppManager } from "../appManager/appManager"
import { Color } from "nifty-engine/libs/niftyEngine/math/color"
import { ColorScheme } from "nifty-engine/libs/niftyEngine/prototype/colorSchemes/colorScheme"

export class Compositor {
    // Since we cant render multiview textures directly, this texture is treated as the final output before being blitted to the actual screen
    mainMultiviewFramebuffer: Framebuffer
    finalFramebuffer: Framebuffer
    render: Function
    clearColor = ColorScheme.background
    multiviewBlit: MultiviewBlit
    constructor(private device: GPUDevice) {
        this.mainMultiviewFramebuffer = MultiviewFramebuffer.create(device, 1920 / 2, 1080)
        this.finalFramebuffer = new Framebuffer()

        this.multiviewBlit = new MultiviewBlit(this.device)
        var comp = new CompositorPostProcess(this.device)

        this.render = (apps: AppManager) => {
            // Composite
            this.bindFramebuffer(this.mainMultiviewFramebuffer)

            // This was removed as clearing many framebuffers was expensive
            // TODO add this back if some apps need there own framebuffer
            // for (var fb of apps.appContainers) {
            //     comp.blit(fb.framebuffer)
            // }

            // Blit to screen
            this.device.gl.bindFramebuffer(this.device.gl.DRAW_FRAMEBUFFER, this.finalFramebuffer.glFramebuffer)
            this.device.gl.viewport(0, 0, this.finalFramebuffer.getWidth(), this.finalFramebuffer.getHeight());
            this.multiviewBlit.blit(this.mainMultiviewFramebuffer)
        }
    }

    clearMainMultiviewFramebuffer() {
        // Blending needs to be set to support auto blending alpha, (if this is not set alpha is always overwritten)
        this.device.gl.enable(this.device.gl.BLEND);
        //this.device.gl.disable(this.device.gl.DEPTH_TEST);
        this.device.gl.blendFunc(this.device.gl.SRC_ALPHA, this.device.gl.ONE_MINUS_SRC_ALPHA);

        // Draw to multiview texture
        this.bindFramebuffer(this.mainMultiviewFramebuffer)
        this.device.gl.clearColor(this.clearColor.r, this.clearColor.g, this.clearColor.b, 1)
        this.device.gl.clear(this.device.gl.COLOR_BUFFER_BIT | this.device.gl.DEPTH_BUFFER_BIT | this.device.gl.STENCIL_BUFFER_BIT);
    }

    createAppFramebuffer() {
        var fb = this.mainMultiviewFramebuffer//MultiviewFramebuffer.create(this.device, 1920 / 2, 1080)
        return fb
    }

    public bindFramebuffer(t: Framebuffer) {
        this.device.gl.bindFramebuffer(this.device.gl.DRAW_FRAMEBUFFER, t.glFramebuffer)
        this.device.gl.viewport(0, 0, t.color.width, t.color.height);
    }
}