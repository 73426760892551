import { App, AppFrame, Light } from "../../niftyReality/app"
import { TransformNode } from "nifty-engine/libs/niftyEngine/sceneGraph/transformNode"
import { Framebuffer } from "nifty-engine/libs/niftyEngine/gfx/framebuffer"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh"
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial"
import { NiftyOS } from "../niftyOS"
import { DragComponent } from "../components/dragComponent"

export class AppContainer {
    app: App
    containerSpace: TransformNode
    appSpace: TransformNode
    taskbar: Mesh<UberMaterial>
    dragComponent: DragComponent
    frame = new AppFrame()
    lights = [new Light()]
    appFocused = false
    constructor(public framebuffer: Framebuffer, private os: NiftyOS) {
        this.containerSpace = new TransformNode()
        this.appSpace = new TransformNode();
        this.updateXRFrame()
        this.app = new App(() => {
            return this.frame
        })
        this.containerSpace.addChild(this.appSpace)

        this.taskbar = new Mesh(os.assets.vertexAttributes.sphere, os.assets.materials.dark)
        this.taskbar.scale.scaleInPlace(0.2)
        this.taskbar.position.y -= this.taskbar.scale.y / 2
        this.containerSpace.addChild(this.taskbar)

        os.stage.nodes.push(this.containerSpace)

        this.dragComponent = new DragComponent(this.containerSpace)
    }

    private updateXRFrame() {
        // Update frame
        this.frame.glContext = this.os.device.gl
        this.frame.framebuffer = this.framebuffer.glFramebuffer!
        this.frame.framebufferWidth = this.framebuffer.getWidth()
        this.frame.framebufferHeight = this.framebuffer.getHeight()
        this.frame.anchor = this.appSpace.worldMatrix.m as Float32Array
        this.frame.appFocused = this.appFocused
        this.frame.xrFrame = this.os.xr.currentXRFrame!
        this.frame.xrRefSpace = this.os.xr.refSpace
        this.lights[0].worldMatrix = this.os.stage.lights[0].worldMatrix.m as Float32Array
        this.lights[0].brightness = this.os.stage.lights[0].brightness
        this.frame.lights = this.lights
    }

    update(curTime: number, delta: number) {
        this.appSpace.computeWorldMatrix(true)
        this.updateXRFrame()
        this.app.update(curTime, delta)
    }

    dispose() {
        var ind = this.os.stage.nodes.indexOf(this.containerSpace)
        if (ind > -1) {
            this.os.stage.nodes.splice(ind, 1)
        }
        this.app.dispose()
    }
}