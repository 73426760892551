import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh"
import { DisplayText } from "./displayText"
import { Stage } from "../niftyRealityAppHelpers/stage"
import { Ray } from "nifty-engine/libs/niftyEngine/math/ray"
import { HitResult, Hit } from "nifty-engine/libs/niftyEngine/prototype/hit"
import { Box } from "./box"
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3"
import { BoxWithPlane } from "./boxWithPlane"

export class InteractionHandler {
    hittable: Array<Mesh<any>> = []
    buttons: Array<BoxWithPlane> = []

    constructor(public stage: Stage) {

    }

    ray = new Ray()
    result = new HitResult()
    checkHit(world: Float32Array) {
        this.stage.rayInAppSpaceFromWorldRayMatrixToRef(world, this.ray)
        this.result.reset()
        var minDist = Infinity
        for (var node of this.buttons) {
            Hit.rayIntersectsMesh(this.ray, node.plane, this.result)
            if (this.result.hitDistance && this.result.hitDistance < minDist) {
                minDist = this.result.hitDistance
            }
        }
        return minDist
    }

    hitObjects: { [key: string]: { obj: Mesh<any> | null, coord: Vector3 } } = {}
    handleButtons() {
        for (var c of this.stage.controllers) {
            var ray = c.getRay()
            if (!this.hitObjects[c.hand]) {
                this.hitObjects[c.hand] = { obj: null, coord: new Vector3() }
            }
            this.hitObjects[c.hand].obj = null

            var minDist = Infinity

            for (var node of this.buttons) {
                Hit.rayIntersectsMesh(ray, node.plane, this.result)
                if (this.result.hitDistance && this.result.hitDistance < minDist) {
                    this.hitObjects[c.hand].obj = node.plane
                    this.hitObjects[c.hand].coord.copyFrom(this.result.hitTexcoord)
                    minDist = this.result.hitDistance
                }
            }
        }
        this.buttons.forEach((o) => {
            var hit = false
            var coord: Vector3 | null = null
            var click = false
            for (var c of this.stage.controllers) {
                if (this.hitObjects[c.hand]) {
                    var obj = this.hitObjects[c.hand].obj
                    if (obj == o.plane /*&& c.isHoveringApp(this.app)*/) {
                        hit = true
                        coord = this.hitObjects[c.hand].coord
                        if (c.primaryButton.justDown) {
                            click = true
                        }
                        break;
                    }
                }
            }
            o.pointer.updateHover.notifyObservers({ hovered: hit, pos: coord })
            if (click) {
                o.pointer.down.notifyObservers(null)
            }
        })
    }
}