import { UIComponent } from "./uiComponent"
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh"
import { Texture } from "nifty-engine/libs/niftyEngine/gfx/texture"


export class Box extends UIComponent {
    box: Mesh<UberMaterial>
    private boxTexture: Texture
    constructor() {
        super()

        this.box = new Mesh(UIComponent.getAssets().vertexAttributes.cube, UIComponent.getAssets().materials.dark);
        this.addChild(this.box)

        this.dimensions = new Proxy(this.dimensions, {
            set: (obj: any, prop, value) => {
                obj[prop] = value;
                this.box.scale.copyFrom(this.dimensions)
                return true
            }
        })
    }
}