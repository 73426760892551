import { App } from "../../niftyReality/app"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh";
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3";
import { NiftyReality } from "../../niftyReality/niftyReality";
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack";
import { Stage } from "../../niftyRealityAppHelpers/stage";
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial";
import { NiftyOS } from "../niftyOS";
import { Matrix4 } from "nifty-engine/libs/niftyEngine/math/matrix4";
import { DefaultVertexData } from "nifty-engine/libs/niftyEngine/prototype/defaultVertexData";


NiftyReality.registerApp({
    appName: "Move light",
    iconImage: "/public/img/demoBlocks.png",
    create: async (app: App) => {
        // Create rendering stage
        var stage = new Stage(new GPUDevice(app.getCurrentFrame().glContext))

        // Load assets
        var assets = new SimpleAssetPack()
        await assets.load(stage.device)

        // Create a mesh and add it to the scene
        var mesh = new Mesh<UberMaterial>(assets.vertexAttributes.sphere, assets.materials.white)
        mesh.scale.scaleInPlace(0.01)
        assets.materials.white.albedoColor.scaleInPlace(100000)
        mesh.position.y = mesh.scale.y / 2 + 0.1
        stage.nodes.push(mesh)

        // Since rotation of the mesh is a quaternion we'll create an euler roation
        var eulerRot = new Vector3(0, 0, 0)

        // set light to default
        NiftyOS.GetOS().stage.lights[0].brightness = 1

        // Render loop
        app.update = (curtime, delta) => {
            // Update stage
            stage.updateFromFrame(app.getCurrentFrame())

            // Rotate 360 degrees every second around the y axis
            eulerRot.y += (delta / 1000) * (Math.PI * 2)
            mesh.rotation.fromEuler(eulerRot.x, eulerRot.y, eulerRot.z)

            var pos = new Vector3()
            var mat = new Matrix4()
            mat.copyFromArrayBufferView(app.getCurrentFrame().anchor)


            mesh.computeWorldMatrix()
            mat.multiplyToRef(mesh.worldMatrix, mat)

            mat.decompose(pos)
            NiftyOS.GetOS().stage.lights[0].position.copyFrom(pos)

            stage.controllers.forEach((c) => {
                NiftyOS.GetOS().stage.lights[0].brightness += c.primaryJoystick.x * delta / 10
                if (NiftyOS.GetOS().stage.lights[0].brightness < 0) {
                    NiftyOS.GetOS().stage.lights[0].brightness = 0
                }
            })

            // Render nodes in the scene
            stage.render()
        }

        app.dispose = () => {
            assets.dispose()
        }
    }
})
