import { Compositor } from "./compositor/compositor"
import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice"
import { InputManager } from "./input/inputManager"
import { UberProgram } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberProgram"
import { Texture } from "nifty-engine/libs/niftyEngine/gfx/texture"
import { DefaultVertexData } from "nifty-engine/libs/niftyEngine/prototype/defaultVertexData"
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh"
import { Light } from "nifty-engine/libs/niftyEngine/sceneGraph/light"
import { TransformNode } from "nifty-engine/libs/niftyEngine/sceneGraph/transformNode"
import { AppManager } from "./appManager/appManager"
import { DefaultAssets } from "./defaultAssets/defaultAssets"
import { AppSpec } from "../niftyReality/appSpec"
import { XRManager, XRState } from "./XR/xrManager"
import { Loop } from "nifty-engine/libs/niftyEngine/prototype/loop"
import { MultiviewFramebuffer } from "nifty-engine/libs/niftyEngine/prototype/multiviewFramebuffer"
import { RenderWindow } from "nifty-engine/libs/niftyEngine/gfx/renderWindow"
import { Vector3 } from "nifty-engine/libs/niftyEngine/math/vector3"
import $ = require("jquery")
import { Hit, HitResult } from "nifty-engine/libs/niftyEngine/prototype/hit"
import { Ray } from "nifty-engine/libs/niftyEngine/math/ray"
import { AppContainer } from "./appManager/appContainer"
import { Stage } from "../niftyRealityAppHelpers/stage";
import { SimpleAssetPack } from "nifty-engine/libs/niftyEngine/prototype/simpleAssetPack"
import { GLBLoader } from "nifty-engine/libs/niftyEngine/fileLoaders/glbLoader"
import { Filesystem } from "../niftyRealityAppHelpers/filesystem"

export class NiftyOS {
    compositor: Compositor
    appManager: AppManager
    inputManager: InputManager
    device: GPUDevice
    window: RenderWindow
    assets: SimpleAssetPack
    xr: XRManager
    stage: Stage
    private fileSysten = new Filesystem("niftyOS_FS_")
    private worldMesh: TransformNode;
    constructor() {

    }
    /**
   * Allow later loaded scripts to access the os
   */
    static GetOS() {
        var global = window as any;
        return global._niftyOS as NiftyOS;
    }
    async boot() {
        // Make accessible to apps
        var global = window as any;
        global._niftyOS = this

        // Initialize os components
        this.device = new GPUDevice()
        this.window = new RenderWindow(this.device, true)
        this.xr = new XRManager(this.device)
        this.compositor = new Compositor(this.device)
        this.compositor.finalFramebuffer = this.window.getNextFramebuffer()
        this.appManager = new AppManager(this.compositor, this)
        this.assets = new SimpleAssetPack()
        this.stage = new Stage(this.device)
        await this.assets.load(this.device)

        // Load controller models
        var controller = new Mesh(this.assets.vertexAttributes.cube, this.assets.materials.dark)
        this.stage.leftController.addChild(controller)
        controller.scale.scaleInPlace(0.03)
        controller.scale.z = 0.05
        this.stage.nodes.push(controller)
        this.stage.leftController.rayHitMesh = new Mesh(this.assets.vertexAttributes.cube, this.assets.materials.gray)
        this.stage.leftController.rayHitMesh.scale.scaleInPlace(0.01)
        this.stage.nodes.push(this.stage.leftController.rayHitMesh)
        var controller = new Mesh(this.assets.vertexAttributes.cube, this.assets.materials.dark)
        this.stage.rightController.addChild(controller)
        controller.scale.scaleInPlace(0.03)
        controller.scale.z = 0.05
        this.stage.nodes.push(controller)
        this.stage.rightController.rayHitMesh = new Mesh(this.assets.vertexAttributes.cube, this.assets.materials.gray)
        this.stage.rightController.rayHitMesh.scale.scaleInPlace(0.01)
        this.stage.nodes.push(this.stage.rightController.rayHitMesh)
        var meshes: Array<Mesh<any>> = []
        for (var i = 0; i < 25; i++) {
            meshes[i] = new Mesh(this.assets.vertexAttributes.cube, i == 9 ? this.assets.materials.focus : this.assets.materials.dark)
            meshes[i].scale.scaleInPlace(0.02)
            this.stage.nodes.push(meshes[i])
        }
        this.stage.leftController.meshes = meshes
        meshes = []
        for (var i = 0; i < 25; i++) {
            meshes[i] = new Mesh(this.assets.vertexAttributes.cube, i == 9 ? this.assets.materials.focus : this.assets.materials.dark)
            meshes[i].scale.scaleInPlace(0.02)
            this.stage.nodes.push(meshes[i])

        }
        this.stage.rightController.meshes = meshes

        // Create default app container for the OS to render OS elements like taskbars/controllers
        var osApp = new AppContainer(this.compositor.createAppFramebuffer(), this)

        // Setup render loop
        var lastTime = 0
        var renderCallback = (time: any, xrFrame?: XRFrame) => {
            // calculate deltaTime
            var delta = time - lastTime
            lastTime = time

            // Update camera when in XR
            if (this.xr.state == XRState.IN_XR) {
                this.xr.update(xrFrame)
            }

            // Update os world state (need to do this before checking controller state, then render)
            osApp.update(time, delta)

            // Remove scene light to not override light update in os scene
            var sceneLight = osApp.app.getCurrentFrame().lights.pop()!
            this.stage.updateFromFrame(osApp.app.getCurrentFrame())
            osApp.app.getCurrentFrame().lights.push(sceneLight)

            // Update dragged object position before casting rays
            this.appManager.appContainers.forEach((container) => {
                var drag = container.dragComponent
                drag.update(delta)
            })

            for (var c of this.stage.controllers) {
                // Check which app is hovered
                var closestHit = Infinity
                var hr = new HitResult()
                var ray = new Ray()
                ray.applyMatrixToRef(c.worldMatrix, ray)

                c.hoveredTaskbar = false
                for (var container of this.appManager.appContainers) {
                    Hit.rayIntersectsMesh(ray, container.taskbar, hr)
                    if (hr.hitDistance && hr.hitDistance < closestHit) {
                        closestHit = hr.hitDistance
                        c.hoveredTaskbar = true
                        c.hoveredApp = container
                    }

                    var dist = container.app.castRay(c.worldMatrix.m as Float32Array)
                    if (dist && dist < closestHit) {
                        closestHit = dist
                        c.hoveredTaskbar = false
                        c.hoveredApp = container
                    }
                }

                // Update ray hit mesh
                var pos = new Vector3()
                pos.copyFrom(ray.direction)
                pos.scaleInPlace(closestHit)
                pos.addToRef(ray.origin, pos)
                c.rayHitMesh.position.copyFrom(pos)

                // add/remove dragging
                var shouldDelete: null | AppContainer = null
                this.appManager.appContainers.forEach((container) => {
                    var drag = container.dragComponent
                    // If drag should be started
                    if (c.hoveredApp == container) {
                        if (c.primaryButton.justDown && c.hoveredTaskbar) {
                            this.appManager.activeApp = container
                            drag.start(c)
                            this.appManager.appContainers.forEach((container) => {
                                container.appFocused = false
                            })
                        }
                    }
                    if (drag.isDragging() && (c.primaryButton.justUp || c.backButton.justDown)) {
                        drag.end()
                        this.appManager.appContainers.forEach((container) => {
                            container.appFocused = false
                        })
                        container.appFocused = true

                        // Delete the container
                        if (c.backButton.justDown) {
                            shouldDelete = container
                        }
                    }
                })
                if (shouldDelete) {
                    this.appManager.disposeApp(shouldDelete)
                }
            }

            // Render composited apps
            this.compositor.clearMainMultiviewFramebuffer()

            // Render OS world
            this.stage.render()

            // Run app update loop
            for (var container of this.appManager.appContainers) {
                container.update(time, delta)
            }

            // TODO should app manager really be passed here?
            this.compositor.render(this.appManager)
        }
        var loopController = new Loop(requestAnimationFrame, renderCallback)

        // Start XR on click
        var enterVR = document.createElement("button")
        enterVR.innerHTML = "Click here to enter NiftyReality"
        enterVR.style.position = "absolute"
        enterVR.style.left = "50%"
        enterVR.style.top = "70%"
        enterVR.style.width = "300px"
        enterVR.style.height = "100px"
        enterVR.style.marginLeft = "-150px"
        document.body.appendChild(enterVR)
        enterVR.onclick = async () => {
            if (loopController) {
                await loopController.stop()
            }
            console.log("Trying to enter XR")
            if (await this.xr.canStart()) {
                try {
                    console.log("STARTING XR")
                    await this.xr.start()
                    console.log("XR Started")
                    this.compositor.finalFramebuffer = this.xr.getNextFramebuffer()
                    this.compositor.mainMultiviewFramebuffer = MultiviewFramebuffer.create(this.device, this.compositor.finalFramebuffer.getWidth() / 2, this.compositor.finalFramebuffer.getHeight())

                    osApp.framebuffer = this.compositor.createAppFramebuffer()
                    for (var c of this.appManager.appContainers) {
                        c.framebuffer = this.compositor.createAppFramebuffer()
                    }

                    loopController = new Loop((x: any) => { this.xr.session!.requestAnimationFrame(x) }, renderCallback)
                } catch (e) {
                    enterVR.innerHTML = e
                }

            } else {
                enterVR.innerHTML = "Browser seems to not be supported, join our chatroom at niftykick.com and we will help you out"
            }
        }

        // Inject app eg. http://localhost:3000/?debugApp=http://localhost:5000/dist/helloWorld.js
        var app = this.getQueryParams("debugApp", window.location.href)
        if (app) {
            this.injectApp(app)
        }


        // Redirect to https in prod
        if (location.protocol !== 'https:' && !location.href.match("^http://localhost")) {
            location.replace(`https:${location.href.substring(location.protocol.length)}`);
        }

        // Parse url params
        var defaultApp = this.getQueryParams("defaultApp", window.location.href)
        if (defaultApp) {
            if (defaultApp == "avatarChat") {
                require("./prototypeApps/avatarChat")
            }
        } else {
            // Launch default app    
            require("./prototypeApps/launcher")
            require("./prototypeApps/avatarChat")
            // require("./prototypeApps/mediaPlayer")
            // require("./prototypeApps/colorDisplay")
            require("./prototypeApps/settings")
            // require("./prototypeApps/basic")
            require("./prototypeApps/bball")
            // require("./prototypeApps/blockSmasher")
            // require("./prototypeApps/buttons")
            require("./prototypeApps/clock")
            // require("./prototypeApps/cloudPC")
            // require("./prototypeApps/face")
            require("./prototypeApps/speedBag")
            //require("./prototypeApps/windowWorld")
            require("./prototypeApps/voxelEditor")
            require("./prototypeApps/flight")
            // require("./prototypeApps/modelViewer")
            require("./prototypeApps/pbrTest")
            // require("./prototypeApps/konvaUI")
            require("./prototypeApps/moveLight")
            // require("./prototypeApps/recordMe")
            // require("./prototypeApps/recordMePlayback")
            // require("./prototypeApps/niftyHL")
        }

        // Load default world
        var bootup = this.fileSysten.getFile("bootup") || {}
        var worldFile = this.getQueryParams("worldGlb", window.location.href)
        if (worldFile) {
            // var worldObj = JSON.parse((world as any).replaceAll("%22", "\"").replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": '))
            // console.log(worldObj)
            this.loadWorld(worldFile)
        } else {
            if (bootup.worldGlb) {
                this.loadWorld(bootup.worldGlb)
            }
        }

        if (bootup.brightness) {

            this.setBrightness(bootup.brightness)
        }
    }

    registeredApps = new Array<AppSpec>()
    onRegisterApp = (appSpec: AppSpec) => {

    }
    launcher: AppContainer
    /**
   * Initialize and position app
   */
    registerApp(appSpec: AppSpec) {
        if (!this.launcher) {
            this.launcher = this.launchApp(appSpec)
        } else {
            this.registeredApps.push(appSpec)
            this.onRegisterApp(appSpec)
        }
    }
    launchApp(appSpec: AppSpec) {
        var appContainer = this.appManager.createApp()
        appContainer.containerSpace.position.y = 1
        appContainer.containerSpace.position.z = -2
        appContainer.containerSpace.rotation.fromEuler(0, Math.PI, 0)
        appSpec.create(appContainer.app)
        return appContainer
    }

    injectApp(url: string) {
        $("body").append('<script type="text/javascript" src="' + url + '"></script>')
    }

    public getQueryParams = (params: string, url: string) => {

        let href = url;
        //this expression is to get the query strings
        let reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
        let queryString = reg.exec(href);
        return queryString ? queryString[1] : null;
    };

    setBrightness(val: number) {
        if (!isNaN(val)) {
            val = val < 0.1 ? 0.1 : val
            this.compositor.multiviewBlit.brightness = val
            var bootup = this.fileSysten.getFile("bootup") || {}
            bootup.brightness = val
            this.fileSysten.saveFile("bootup", bootup)
        }
    }

    async loadWorld(url: string | null) {
        try {
            var bootup = this.fileSysten.getFile("bootup") || {}
            bootup.worldGlb = url
            this.fileSysten.saveFile("bootup", bootup)

            if (this.worldMesh) {
                var index = this.stage.nodes.indexOf(this.worldMesh)
                if (index >= 0) {
                    this.stage.nodes.splice(index, 1)
                }
            }
            if (url == "custom1") {
                this.worldMesh = new TransformNode()
                var mat = this.assets.createMaterial(this.stage.device)
                mat.albedoColor.set(0.05 * 5, 0.07 * 5, 0.1 * 5)
                mat.metallic = 0.2
                mat.roughness = 0.9
                var floor = new Mesh(this.assets.vertexAttributes.cube, mat)
                floor.scale.set(100, 0.001, 100)
                this.worldMesh.addChild(floor)
                var vert = DefaultVertexData.createSphereWithInvertedNormalsVertexData(this.device)
                var sky = new Mesh(vert, mat)
                sky.scale.scaleInPlace(100)
                this.worldMesh.addChild(sky)
                this.stage.nodes.push(this.worldMesh)
            } else {
                var res = await GLBLoader.load(this.stage.device, url as any)
                this.stage.nodes.push(res[0])
                this.worldMesh = res[0]
            }

        } catch (e) {
            console.log("failed to load world model")
        }

        // Debug size cube
        // var cube = new Mesh(this.assets.vertexAttributes.cube, this.assets.materials.dark)
        // cube.position.y = 0.5
        // this.stage.nodes.push(cube)
    }
}