import { GPUDevice } from "nifty-engine/libs/niftyEngine/gfx/gpuDevice"
import { Framebuffer } from "nifty-engine/libs/niftyEngine/gfx/framebuffer"
import { XRHead } from "nifty-engine/libs/niftyEngine/prototype/xr/xrHead"

export enum XRState {
    IN_XR,
    NOT_IN_XR
}

export class XRManager {
    framebuffers = new Array<Framebuffer>()
    state = XRState.NOT_IN_XR
    leftControllerSource: XRInputSource | null = null
    rightControllerSource: XRInputSource | null = null
    currentXRFrame?: XRFrame

    xr = navigator.xr!
    session: XRSession | null = null
    refSpace: XRReferenceSpace | null = null

    constructor(private gpuDevice: GPUDevice) {

    }

    getNextFramebuffer() {
        return this.framebuffers[0]
    }

    async canStart() {
        if (!this.xr) {
            return false
        }
        var supported = await this.xr.isSessionSupported("immersive-vr")
        if (!supported) {
            return false
        }
        return true
    }

    update(frame?: XRFrame) {
        this.currentXRFrame = frame
    }

    async start() {
        this.session = await this.xr.requestSession('immersive-vr', {
            requiredFeatures: ['local-floor']
        })
        let glLayer = new XRWebGLLayer(this.session, this.gpuDevice.gl);
        this.framebuffers[0] = new Framebuffer()
        this.framebuffers[0].glFramebuffer = glLayer.framebuffer
        this.framebuffers[0].getWidth = () => {
            return glLayer.framebufferWidth
        }
        this.framebuffers[0].getHeight = () => {
            return glLayer.framebufferHeight
        }
        // It's heighly reccommended that you set the near and far planes to
        // something appropriate for your scene so the projection matricies
        // WebVR produces have a well scaled depth buffer.
        this.session.updateRenderState({ baseLayer: glLayer, depthFar: XRHead.FAR_Z, depthNear: XRHead.NEAR_Z });
        this.refSpace = await this.session.requestReferenceSpace('local-floor')
        this.state = XRState.IN_XR
    }
}