import { UIComponent } from "./uiComponent"
import { VideoTexture } from "nifty-engine/libs/niftyEngine/prototype/videoTexture"
import { UberMaterial } from "nifty-engine/libs/niftyEngine/prototype/uberShader/uberMaterial"
import { Mesh } from "nifty-engine/libs/niftyEngine/sceneGraph/mesh"
import { CanvasTexture } from "nifty-engine/libs/niftyEngine/prototype/canvasTexture"
import { Texture } from "nifty-engine/libs/niftyEngine/gfx/texture"
import { Box } from "./box"


export class BoxWithPlane extends Box {
    plane: Mesh<UberMaterial>
    constructor() {
        super()
        var mat = new UberMaterial(UIComponent.getAssets().device, UIComponent.getAssets().programs.default)
        mat.albedoTexture = UIComponent.getAssets().textures.default
        mat.metallicTexture = UIComponent.getAssets().textures.default
        mat.roughnessTexture = UIComponent.getAssets().textures.default
        this.plane = new Mesh(UIComponent.getAssets().vertexAttributes.plane, mat);
        this.addChild(this.plane)
        this.dimensions = new Proxy(this.dimensions, {
            set: (obj: any, prop, value) => {
                obj[prop] = value;
                this.plane.scale.copyFrom(this.dimensions)
                this.plane.position.z = -(this.dimensions.z / 2) - 0.001
                return true
            }
        })
    }
}